import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views'
Vue.use(VueRouter)
const meta = {
    keepAlive: true
}
const routes = [{
    path: '/',
    name: 'index',
    component: index,
    meta
},
{
    path: '/type/1',
    component: () => import('@/views/1'),

},
{
    path: '/type/2',
    component: () => import('@/views/2'),

},
{
    path: '/type/3',
    component: () => import('@/views/3'),

},
{
    path: '/type/4',
    component: () => import('@/views/4'),

},
{
    path: '/type/4_2',
    component: () => import('@/views/4_2'),

},
{
    path: '/farm',
    component: () => import('@/views/farm'),
},
{
    path: '/article',
    component: () => import('@/views/article'),
},
// {
//     path: '/show',
//     name: 'show',
//     component: () => import('@/views/show'),
// },
{
    path: '*',
    redirect: "/"
},
]



console.log(process.env)
const router = new VueRouter({
    // mode: 'history',
    mode: "hash",
    base: process.env.BASE_URL,
    // base: "/dist/",
    routes
})

router.beforeEach((to, from, next) => {
 
    _hmt && _hmt.push(['_trackPageview', '/#' + to.fullPath]);
    next();
});
// 部分白色背景页面
// const whiteList = ['/meeting/report', '/question/answer', '/integral/record', '/login']
// router.beforeEach(({ path }, from, next) => {
//   console.log(path)
//   if (whiteList.includes(path)) {
//     document.querySelector('body').setAttribute('style', 'background-color:#ffffff;')
//   } else {
//     document.querySelector('body').setAttribute('style', '')
//   }
//   next();
// })


export default router