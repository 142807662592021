// 服务器上传文件地址
export const IMG_UP_URL = "https://2023-cloud-library.oss-cn-hangzhou.aliyuncs.com/"
// 服务器静态文件地址
export const IMG_URL = "/uploads/miniprogram/"

import router from '@/router'
import { setTokenAUTH } from "@/api/auth";
export const logOut = () => {
    setTokenAUTH('');
    router.push('/select_login');
}
// 验证手机号
export function isPhoneAvailable(str) {
    const myreg = /^[1][0-9][0-9]{9}$/
    return myreg.test(str)
}