<template>
  <div class="title">
    <van-icon name="arrow-left" v-if="back" @click="$router.back()" />
    <div class="title-left">
      {{ name }}
    </div>
    <div class="title-right">
      <slot></slot>
    </div>

    <router-link v-if="more" :to="to" class="title-right"
      >{{ more }}
      <van-icon v-if="to" name="arrow" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "title",
  props: {
    back: {
      type: Boolean,
      default: true,
    },
    // 标题
    name: {
      type: String,
    },
    //是否显示更多按钮
    more: {
      type: String,
    },
    // 跳转路径
    to: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin: 0;
  padding: 15px 0;
  line-height: 20px;
  background-color: @green;
  color: @white;
  .van-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  .title-left {
    font-size: 17px;
    // font-weight: bold;
    // position: absolute;
    // left: 0;
    // top: 50%;
    // transform: translateY(-50%);
  }
  .title-right {
    font-size: 13px;
    color: @gray-7;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
