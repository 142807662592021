import myAxios from "./axios"



// 文件上传
export const fileUpload = (data) => myAxios({ url: 'api/common/upload', method: 'post', data, headers: { 'Content-Type': 'multipart/form-data' } })

// 获取二维码
export const getShareUrl = (data) => myAxios({ url: 'api/index/getShareUrl', })

// 获取保存生成的图片
export const submitPoster = (data) => myAxios({ url: 'api/poster/submit', method: 'post', data, })

//添加访问次数
export const addVisitNum = (data) => myAxios({ url: 'api/poster/addVisitNum', method: 'post', data, })

