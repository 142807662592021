<template>
  <div class="index">
    <swiper>
      <swiper-slide>
        <img :src="$getImgUrl('assets/swiper.png')" alt="" />
      </swiper-slide>
      <swiper-slide>
        <img :src="$getImgUrl('assets/swiper.png')" alt="" />
      </swiper-slide>
    </swiper>
    <div class="box">
      <img :src="$getImgUrl('assets/box-1.png')" alt="" />
      <div class="type">
        <img
          :src="$getImgUrl('assets/type-1.png')"
          @click="$router.push('/type/1')"
          alt=""
        />
        <img
          :src="$getImgUrl('assets/type-2.png')"
          @click="$router.push('/type/2')"
          alt=""
        />
        <img
          :src="$getImgUrl('assets/type-3.png')"
          @click="$router.push('/type/3')"
          alt=""
        />
        <img
          :src="$getImgUrl('assets/type-4.png')"
          @click="$router.push('/type/4')"
          alt=""
        />
      </div>
    </div>
    <div class="box">
      <img :src="$getImgUrl('assets/box-2.png')" alt="" />
      <div class="status">
        <div v-for="n in 4">
          <img
            :src="$getImgUrl(`assets/type/${n}-${status[n - 1]}.png`)"
            alt=""
          />
          <p :style="`opacity:${0.6 + 0.1 * status[n - 1]}`">
            {{ titleList[n - 1] }}
          </p>
          <p
            class="status-label"
            :style="`opacity:${0.6 + 0.1 * status[n - 1]}`"
          >
            {{ titleEnglistList[n - 1] }}
          </p>
        </div>
      </div>
    </div>
    <img
      class="logo"
      src="@/assets/logo.png"
      @click="
        show = true;
        audioPlay();
      "
      alt=""
    />
    <van-popup v-model="show" class="msg">
      <template v-if="!msg">
        <img src="@/assets/msg-s.png" alt="" class="msg-top" />
        <img src="@/assets/msg-ss.png" alt="" class="msg-center" />
        <p>全部完成了</p>
      </template>
      <template v-else>
        <img src="@/assets/msg-f.png" alt="" class="msg-top" />
        <img src="@/assets/msg-ff.png" alt="" class="msg-center" />
        <p>{{ msg }}还未完成</p>
      </template>
      <van-icon @click="show = false" name="close" />
    </van-popup>

    <van-popup v-model="showIndex" class="mask">
      <div class="video">
        <m-video-player
          v-if="showIndex"
          autoplay
          :src="$getImgUrl('index.mp4')"
          name="index"
        >
        </m-video-player>
      </div>

      <p>
        瓯海区外国语学校创办于2016年，是一所九年一贯制学校。为助力亚运，迎接四方宾客，传播“千年商港，幸福温州”精神，我们推出瓯外师生关于温州老物件人文与科学原理研究的展览。
      </p>
      <p>让我们一起去看看吧！</p>
      <img
        @click="showIndex = false"
        src="@/assets/close.png"
        class="close"
        name="close"
      />
    </van-popup>
    <audio
      id="myAudio"
      autoplay
      :src="$getImgUrl('assets/index.mp3')"
      class="audio"
    ></audio>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      value: "",
      edit: false,
      status: [4, 3, 2, 1],
      titleList: ["农具印章", "盛具印章", "计量印章", "眠居印章"],
      titleEnglistList: [
        "Farm Tools stamp",
        "Tableware stamp",
        "Metrology  stamp",
        "Bedroom architecture stamp",
      ],
      show: false,
      showIndex: true,
    };
  },
  computed: {},
  created() {
    const { titleList } = this;
    const status = [];
    const msg = [];
    for (let n = 1; n <= 4; n++) {
      let s = 1;
      for (let m = 1; m <= 3; m++) {
        if (localStorage.getItem(`read_${n}_${m}`)) {
          s++;
        }
      }
      status.push(s);
      if (s != 4) {
        msg.push(titleList[n - 1]);
      }
    }
    this.msg = msg.join(",");
    this.status = status;
  },
  mounted() {
    this.audioPlay();
  },
  methods: {},
  watch: {
    showIndex() {
      this.audioPlay();
    },
  },
};
</script>
  
<style lang="less" scoped>
.type {
  top: 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 35px;

  > img {
    width: 128px;
    height: 124px;
    margin-bottom: 15px;
    animation: float 2s infinite;
  }
  > img:nth-child(1) {
    transition-delay: 0;
  }
  > img:nth-child(2) {
    animation: float 2s 0.5s infinite;
  }
  > img:nth-child(3) {
    animation: float 2s 0s infinite;
  }
  > img:nth-child(4) {
    animation: float 2s 0.5s infinite;
  }
}

.status {
  top: 12px;
  display: flex;
  padding: 0 24px 0;
  justify-content: space-between;

  > div {
    width: 100px;
    text-align: center;
    font-size: 12px;

    img {
      width: 40px;
    }
    .status-label {
      font-size: 8px;
      color: #9e9d9d;
    }
  }
}

.logo {
  position: fixed;
  width: 52px;
  right: 20px;
  bottom: 12vh;
  animation: float 1.5s infinite;
}
.msg {
  width: 220px;
  height: 240px;
  background-image: linear-gradient(180deg, #fffdf4 0%, #ffffff 100%);
  border-radius: 15px;
  overflow: inherit;
  text-align: center;
  .msg-top {
    position: absolute;
    left: 0;
    transform: translateY(-50%);
  }
  .msg-center {
    width: 110px;
    margin: auto;
    margin: 32px 0 20px;
  }
  p {
    font-size: 18px;
    color: #414141;
    padding: 0 15px;
  }
}
.mask {
  width: 300px;
  font-size: 14px;
  padding-bottom: 10px;
  border-radius: 10px;
  overflow: inherit;
  .video {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }
  img {
  }
  p {
    text-indent: 2em;
    line-height: 1.7;
    padding: 0 15px;
    margin-top: 10px;
  }
}

.van-popup {
  .van-icon {
    position: absolute;
    opacity: 0.68;
    color: #ffffff;
    font-size: 36px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -45px;
  }
}
.close {
  position: absolute;
  color: #ffffff;
  width: 120px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -45px;
}
</style>