function setRem() {
    // 375 默认大小16px; 375px = 23.4375rem ;每个元素px基础上/16
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
      //得到html的Dom元素
    let htmlDom = document.getElementsByTagName('html')[0];
     //设置根元素字体大小
    if (htmlWidth <= 750) {
        htmlDom.style.fontSize = htmlWidth / 23.4375 + 'px';
        htmlDom.style.width = htmlWidth + 'px';
        // htmlDom.style.maxWidth = 750 + 'px';
    } else {
        htmlDom.style.fontSize = 500 / 23.4375 + 'px';
        htmlDom.style.width = 500 + 'px';
        
    }
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function() {
    setRem()
}