import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;
import './unit/rem.js';

import Vant from 'vant';
import 'vant/lib/index.less';

Vue.use(Vant);

import { Lazyload } from 'vant';
Vue.use(Lazyload);

import 'swiper/css/swiper.min.css'
import { Swiper, SwiperSlide, } from 'vue-awesome-swiper'
Vue.component('Swiper', Swiper)
Vue.component('SwiperSlide', SwiperSlide)

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

import MVideoPlayer from '@/components/VideoPlayer'
Vue.component('MVideoPlayer', MVideoPlayer)

import MTitle from '@/components/title';
Vue.component('MTitle', MTitle)

Vue.prototype.$baseUrl = process.env.NODE_ENV == 'development' ? 'http://180.76.37.30/' : ''

import VueDragResize from 'vue-drag-resize'
Vue.component('vue-drag-resize', VueDragResize)


import hls from "videojs-contrib-hls";
Vue.use(hls);

// 全局方法
import $f from '@/unit/$f'
Vue.use($f)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
