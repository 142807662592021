<template>
  <div id="app" ref="app">
    <img src="@/assets/bg.png" class="bg" alt="" />

    <keep-alive>
      <router-view v-if="getKeepAlive"></router-view>
      <!--这里是会被缓存的组件-->
    </keep-alive>
    <router-view v-if="!getKeepAlive"></router-view>

    <!--这里是不会被缓存的组件-->
    <audio
     
      v-if="show"
      id="myAudioType"
      autoplay
      loop
      :src="src"
      class="audio"
    ></audio>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      scrollTop: 0,
      src: "",
      show: true,
    };
  },
  computed: {
    getKeepAlive() {
      const { meta } = this.$route;
      // console.log(meta);
      if (meta) {
        if (meta.keepAlive) {
          return true;
        }
      }
      return false;
    },
    getMP() {
      return this.$store.state.mp;
    },
  },
  async created() {
    // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0fa987ef084ba9a3&redirect_uri=https://chenao.guojishuzi.com/&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect
  },
  mounted() {},
  methods: {},
  watch: {
    $route(to, from) {
      console.log("路由变化了");
      console.log("当前页面路由：" + to.path);
      if (to.path.includes("type")) {
        this.src = require(`@/assets/m/${to.path[6]}.mp3`);

        document.getElementById("myAudioType").play();
        setTimeout(() => {
          document.getElementById("myAudioType").play();
          this.$store.commit("SET_MP", "");
        }, 500);
      } else {
        this.src = "";
      }
      document.addEventListener(
        "WeixinJSBridgeReady",
        () => {
          document.getElementById("video-player").play();
          document.getElementById("myAudioType").play();
        },
        false
      );
    },
    getMP() {
      const { getMP } = this;
      console.log(getMP);
      if (getMP) {
        document.getElementById("myAudioType").play();
      } else if (getMP === false) {
        document.getElementById("myAudioType").pause();
        setTimeout(() => {
          document.getElementById("myAudioType").pause();
        });
      }
      this.$store.commit("SET_MP", "");
    },
  },
};
</script>

<style lang="less">
html {
  margin: 0 auto;
}

div {
  box-sizing: border-box;
}

body {
  background-color: #f8f8f8;
}

p {
  margin: 0;
}

#app {
  margin: auto;
}

img {
  width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  height: auto;
}

.bg {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.box {
  margin: 15px;
  position: relative;

  > img {
    position: relative;
  }

  > div {
    position: absolute;
    width: 100%;
  }

  > .video {
    width: 328px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 10px;
  }
}

.index {
  padding-bottom: 2px;
}

.title-img {
  width: auto;
  height: 52px;
  margin: 0 15px;
}

.wrap {
  margin: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  > img {
    border-radius: 8px;
  }

  .wrap-title {
    font-size: 18px;
    margin: 5px 0;
    color: #2baa1c;
    font-weight: bold;
  }

  .wrap-content {
    padding: 6px 0;
    font-size: 15px;
    line-height: 1.7;
    position: relative;
    text-indent: 2em;
    .wrap-content-span {
      position: absolute;
      bottom: 0;
      right: 4px;
      color: #2baa1c;
    }
  }
}
@keyframes float {
  from {
    transform: translatey(0px);
  }
  65% {
    transform: translatey(8px);
  }
  to {
    transform: translatey(-0px);
  }
}
</style>