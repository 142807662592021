import { IMG_URL, IMG_UP_URL } from "@/unit";
import { fileUpload, } from "@/api";
import { Promise } from "core-js";
import router from '@/router'
export default {
    install(Vue, options) {

        // 上传图片
        Vue.prototype.$afterRead = function ({ file }, key) {
            const list = this[key];
            let param = new FormData(); // 创建form对象
            param.append("file", file); // 通过append向form对象添加数据
            fileUpload(param).then((res) => {
                this.$set(this[key][list.length - 1], 'url', res.data.fullurl)
            })

        }
        // 获取图片地址
        Vue.prototype.$getImgUrl = function (url) {
            // console.log(url)
            url = url || ''
            if (url) {
                if (url.includes("https")) {
                    return url
                }
                if (url.includes(".")) {
                    return IMG_UP_URL + url;
                }
                return IMG_URL + url + ".png";
            }
            return ""
        }
        // 时间格式化
        Vue.prototype.$dateFormat = function (d, fmt = "YYYY-mm-dd") {
            console.log(d)
            const date = new Date(d < 17097938750 ? d * 1000 : d)
            let ret;
            const opt = {
                "Y+": date.getFullYear().toString(),        // 年
                "m+": (date.getMonth() + 1).toString(),     // 月
                "d+": date.getDate().toString(),            // 日
                "H+": date.getHours().toString(),           // 时
                "M+": date.getMinutes().toString(),         // 分
                "S+": date.getSeconds().toString()          // 秒
                // 有其他格式化字符需求可以继续添加，必须转化成字符串
            };
            for (let k in opt) {
                ret = new RegExp("(" + k + ")").exec(fmt);
                if (ret) {
                    fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
                };
            };
            return fmt;
        }

        // 已读状态更新
        Vue.prototype.$setRead = function (n, m) {

            localStorage.setItem(`read_${n}_${m}`, 1);
            if (m == 1) {
                location.href = 'https://www.720yun.com/t/21akn9iedil#scene_id=112460709'
            }
        }
        Vue.prototype.audioPlay = () => {
            document.getElementById("myAudio").play()
            document.getElementById("myAudioType").play();
            document.addEventListener(
                "WeixinJSBridgeReady",
                () => {
                    document.getElementsByTagName('video')[0].play();
                    document.getElementById("myAudioType").play();
                },
                false
            );
        }

        Vue.directive('has', {
            inserted: function (el, binding, vnode) {
                if (vnode.context.$store.state.config[binding.value]) {
                    return
                }
                el.parentNode.removeChild(el);
            },
            update: function (el, binding, vnode) {
                if (vnode.context.$store.state.config[binding.value]) {
                    return
                }
                el.parentNode.removeChild(el);
            }
        })

    }
}